import React from 'react';

import { Layout } from '../components';

const NotFoundPage = () => (
    <Layout title="Not Found">
        <div className='w-full py-40'>
            <div className='w-3/5 mx-auto'>
                <div className='text-primary text-5xl mb-12'>Error: 404</div>
                <div className='text-primary text-2xl'>The page you are looking for does not exist.</div>
            </div>
        </div>
    </Layout>
);

export default NotFoundPage;